import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./GoalResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
import VideoFeedbacks from "../../UI/videoFeedbacks/VideoFeedbacks";

const GoalResult = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.goalResult;
    const themeData = data.themeData
    const gender = localStorage.getItem("gender");
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {

            dataLayer.push({
              "event": "ga4event",
              "event_category": "screen_onboarding_section_goal_appeared",
              "funnel_type": data.campaign,
              "step": pageInfo.order
            });
        amplitude.getInstance().logEvent('screen_onboarding_section_goal_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_goal_tapped');
        nextStep();
    }
    let reviewAuthorId = "reviewAuthor";
    if(gender === 'Male') {
    reviewAuthorId = "reviewAuthorM";
    }
    if(pageInfo.reviewAuthor !== null && pageInfo.reviewAuthor !== undefined ){
    reviewAuthorId = pageInfo.reviewAuthor;
    }
    return (
        <div className={mainContainerClass}>
            <ResultHeader
                url={'/images/result/goal-result.jpg'}
                urlWebp={'/images/result/goal-result.webp'}
                theme={themeData?.menuProgressBackground}
            />
            { pageInfo.video && strings.getLanguage() === 'en'
                ? (
                    <div className={styles.videoFeedback}>
                        <VideoFeedbacks
                            feedbacks={pageInfo.feedbacks}
                            title={false}
                            name={false}
                            view="square"
                            themeData={themeData}
                        />
                    </div>
                )
                :
                <LazyImage
                    className={styles.image}
                    srcWebp={gender === 'Male' ? pageInfo.resultImageMWebp : pageInfo.resultImageWebp}
                    src={gender === 'Male' ? pageInfo.resultImageM : pageInfo.resultImage}
                    alt="" />
            }
            <div className={styles.commentContainer}>
                <div className={styles.titleContainer}>
                    <h2>{strings.goal.result['reviewTitle' + '_' + pageInfo.reviewTitle]}</h2>
                    <img loading="lazy" src="/images/stars.svg" alt="" />
                </div>
                <p>{strings.goal.result[pageInfo.reviewText]}</p>
                <div className={`${styles.authorContainer} flex-between`}>
                    <span>
                   {strings.goal.result[reviewAuthorId]}
                    </span>
                </div>
            </div>
            <ContinueButton
                text={strings.continue}
                nextStep={clickContinue}
                theme={themeData.continueButton}
            />
        </div>
    )
}   

export default GoalResult;