import React from 'react';
import './styles/global.scss';
import './styles/reset.scss';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from "redux";
import App from './App';
import { Provider } from 'react-redux';

const defaultState = {
  paymentPeriod: {}
}

const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'SELECT_PERIOD':
      return {...state, paymentPeriod: action.payload}

    default:
      return state;
  }
}

const store = createStore(reducer);
const container = document.getElementById("root");
const root = createRoot(container);

const Root = () => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    localStorage.setItem('search', queryString);
    localStorage.setItem('queryParams', queryParams);
    localStorage.setItem('campaign', queryParams.get('r'))
    localStorage.setItem('checkout', queryParams.get('cht'))
    localStorage.setItem('plan_progress', queryParams.get('ppv'))
    localStorage.setItem('country', queryParams.get('country'))
    localStorage.setItem('partner', queryParams.get('partner'))
    localStorage.setItem('lang', queryParams.get('lang'))
    localStorage.setItem('nutrition', queryParams.get('ntr'))
    localStorage.setItem('special_offer', queryParams.get('so'))
    localStorage.setItem('theme', queryParams.get('t'))
    localStorage.setItem('tm', queryParams.get('tm'))

    try{
     let fbclidValue = queryParams.get('fbclid');
        if(fbclidValue !== null && fbclidValue !== undefined){
        let fbclid = 'fb.1.' + Date.now() + '.' + fbclidValue;
        localStorage.setItem('fbclid', fbclid);
        }
    } catch(e){

    }


    localStorage.setItem('utm_source', queryParams.get('utm_source'))
    localStorage.setItem('utm_medium', queryParams.get('utm_medium'))
    localStorage.setItem('utm_campaign', queryParams.get('utm_campaign'))
    localStorage.setItem('utm_content', queryParams.get('utm_content'))

    if (window.location.href.includes("/#/")) {
        window.history.replaceState({}, document.title, window.location.href.replace('/#/', '/'));
    }

    return (
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    );
};

root.render(<Root />);
