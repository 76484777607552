import {useState, useEffect, useRef} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from "react-router";
import {Pagination, Navigation, Autoplay} from "swiper";
import { useDispatch } from "react-redux";
import { useFeatureValue } from "@growthbook/growthbook-react";
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import sha256 from 'crypto-js/sha256';
import moment from 'moment/moment';

import GetButton from "../../components/UI/paywall/getProgramButton/GetButton";
import ResultsCard from "../../components/UI/paywall/paywallResultsCard/ResultsCard";
import StartTrial from "../../components/UI/paywall/startTrial/StartTrial";
import StartDiscount from "../../components/UI/paywall/startDiscount/StartDiscount";
import XPercentDiscount from "../../components/UI/paywall/xPercentDiscount/XPercentDiscount";
import MealPlanBonus from "../../components/UI/paywall/mealPlanBonus/MealPlanBonus";
import PaymentRateCards from "../../components/UI/paywall/paymentRateCards/PaymentRateCards";
import ImageSaleAd from "../../components/UI/paywall/imageSaleAd/ImageSaleAd";
import ErrorBoundary from "../../components/error/errorCatcher";

import paywallData from "../../core/static/paywall.json";

import styles from "./Paywall.module.scss";
import "./Paywall.scss";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

import strings from "../../assets/localization/strings.jsx";
import NutritionSwitcher from "../../components/UI/paywall/nutritionSwitcher/NutritionSwitcher";
import PaywallTimer from "../../components/UI/paywall/paywallTimer/PaywallTimer";
import MealPlanWithTimer from "../../components/UI/paywall/mealPlanWithTimer/MealPlanWithTimer";
import DownloadAppsBox from "../../components/UI/paywall/downloadAppsBox/DownloadAppsBox";
import GuaranteeBox from "../../components/UI/paywall/guaranteeBox/GuaranteeBox";
import VideoFeedbacks from "../../components/UI/videoFeedbacks/VideoFeedbacks";
import StayOnTopBox from "../../components/UI/paywall/stayOnTopBox/stayOnTopBox";
import LazyImage from "../../components/UI/lazyImage/LazyImage";
import PaywallBackground from "../../components/UI/paywall/paywallBackground/PaywallBackground";
import HeaderSwiper from "../../components/UI/paywall/headerSwiper/HeaderSwiper";
import SpecialTitle from "../../components/UI/paywall/specialTitle/SpecialTitle";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = 'e9cb49ddaa71504c0628507456187bbfb9368637';

const Paywall = (props) => {
    const { data } = props;
    const themeData = data.themeData
    const timerRef = useRef(null);
    const productsData = data.products
    const goal = localStorage.getItem('goal') || strings.intro.mainGoal.introGoalFit;
    const discountStatus = localStorage.getItem('discountStatus');
    const pageInfo = discountStatus && discountStatus === 'discount'
                     ? {...data.settings.paywall, ...data.settings.paywall_discount }
                     : discountStatus && discountStatus === 'trial'
                       ? {...data.settings.paywall, ...data.settings.paywall_trial }
                        : data.settings.paywall;

    const targetWeight = localStorage.getItem('targetWeight');
    const paywallCurrentWeight = localStorage.getItem('paywallCurrentWeight');
    const country = data.country
    const [nutritionButton, setNutritionButton] = useState(true);
    const [content, setContent] = useState(paywallData);
    const [textButton, setTextButton] = useState(strings.continue);
    const [rateCards, setRateCards] = useState([]);
    const [stopTimer, setStopTimer] = useState(false);
    const [activeRate, setActiveRate] = useState(productsData[makeProductsType()][1]);
    const value = useFeatureValue("paywall_testing", "fallback");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const feedbacks = pageInfo.results[country]

    function makeProductsType() {
        if(data.partner)
            return data.partner
        if(country === "ny1")
            return `${country}_${value}`

        let typeValues = [country]
        if(['discount', 'trial'].indexOf(discountStatus) > -1)
            typeValues.push(discountStatus)
        if (data.nutrition !== null && data.nutrition === "onh" && nutritionButton)
            typeValues.push('n')
        return typeValues.join('_')
    }

    const nextPage = (btnOrder) => {
        let timer = timerRef.current?.textContent?.split(":") || [0, 0, 0]
        amplitude.getInstance().logEvent('button_onboarding_paywall_subscribe_tapped', {
           paywall: value,
           order: btnOrder,
           product: `$${activeRate.total}_${activeRate.title}`
        });
        localStorage.setItem('timer', JSON.stringify({
           minutes: parseInt(timer[0]),
           seconds: parseInt(timer[1]),
           milliseconds: parseInt(timer[2])
        }));
        if(data.nutrition !== null && data.nutrition === 'onh')
            localStorage.setItem('with_nutrition', nutritionButton);
        setStopTimer(true);
        navigate('/payment')
    }

    const setActiveRateCard = (card) => {
        if(card.checked) {
            setRateCards(rateCards.map(item => {
                if(card.id === item.id) {
                    return {...item, checked: card.checked}
                } else {
                    return {...item, checked: false}
                }
            }))
            setActiveRate(card)
            dispatch({type: 'SELECT_PERIOD', payload: card})
        }
    }

    function get_ga_clientid() {
      var cookie = {};
      document.cookie.split(';').forEach(function(el) {
        var splitCookie = el.split('=');
        var key = splitCookie[0].trim();
        var value = splitCookie[1];
        cookie[key] = value;
      });
    return cookie["_ga"].substring(6);
    }

    function getSessionData() {
    	// Use your own ID here ↓
    	const pattern = /_ga_SSK6F1ZPVF=GS\d\.\d\.(.+?)(?:;|$)/;
    	const match = document.cookie.match(pattern);
    	const parts = match?.[1].split(".");

    	if (!parts) {
    		// Cookie not yet available; wait a bit and try again.
    		window.setTimeout(() => getSessionData(callback), 200);
    		return;
    	}

        let ga_session_id = parts.shift();
        let ga_session_number = parts.shift();
        localStorage.setItem('gsi', ga_session_id);

    }

    useEffect(() => {
        let googleClientId = get_ga_clientid();

        if(googleClientId !== null && googleClientId !== undefined){
        localStorage.setItem('gci', googleClientId);
        getSessionData();
        }


        if(discountStatus) {
            if(discountStatus === 'trial') {
                setTextButton(strings.continueMyTrial)
            }
            if(discountStatus === 'discount') {
                setTextButton(strings.continue)
            }
        }

          try{
              const eventId = `paywall_open.${getCookie('id')}`;
              ReactPixel.track('PaywallOpen', {eventID: eventId});
              TiktokPixel.track('PlaceAnOrder', {event_id: eventId});

              const payload = {
                              data: [
                                {
                                  event_name: "PaywallOpen",
                                  event_time: moment().unix(),
                                  action_source: "website",
                                  event_id: eventId,
                                  user_data: {
                                      client_user_agent: navigator.userAgent,
                                      external_id: sha256(getCookie('id')).toString()
                                  }
                                }
                              ]
                            };
                            //const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]).join();
                            const fbc = localStorage.getItem('fbclid');
                            const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]).join();

                            if(fbc) {
                              payload.data[0].user_data.fbc = fbc;
                            }

                            if(fbp) {
                              payload.data[0].user_data.fbp = fbp;
                            }

                            fetch(`https://graph.facebook.com/v10.0/${(data.domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${data.domain.includes('net')? netToken: token}`, {
                              method: "POST",
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              body: JSON.stringify(payload)
                            })

           const ttPayload = {

                                    "pixel_code": "CP2RST3C77UF83EV1CUG",
                                    "event_id": eventId,
                                    "event": "PlaceAnOrder",
                                    "timestamp": new Date(Date.now()),
                                    "context": {
                                          user: {
                                               external_id: sha256(getCookie('id')).toString()
                                           },
                                          user_agent: navigator.userAgent,
                                     }
                                   };

                                   const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                   const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                   if(ttc) {
                                     ttPayload.context.user.ttclid = ttc;
                                   }

                                   if(ttp) {
                                     ttPayload.context.user.ttp = ttp;
                                   }
                                   try{
                                   fetch('https://smvcqm8fu5.execute-api.us-east-1.amazonaws.com/dev/tiktok_pixel_event', {
                                                                        method: "POST",
                                                                        headers: {
                                                                          'Accept': 'application/json',
                                                                          'Content-Type': 'application/json'
                                                                        },
                                                                        body: JSON.stringify(ttPayload)
                                     });
                                   } catch(e){
                                     console.log("tiktok track event error " + e);
                                   }


          } catch(e){
           console.log("event error: " + e)
          }

        let paywallPrices = makeProductsType()
        let actualRateCarts = productsData[paywallPrices].map(item => { return {...item, checked: false } })
        let activeRateIndex = activeRate.id
        let currActiveRate = { ...actualRateCarts[activeRateIndex], checked: true }
        setActiveRate(currActiveRate)
        actualRateCarts[activeRateIndex] = currActiveRate
        setRateCards(actualRateCarts)
        localStorage.setItem('period', JSON.stringify(actualRateCarts[activeRateIndex]))
        amplitude.getInstance()
            .logEvent('screen_onboarding_paywall_appeared', { paywall: paywallPrices})
        // if(value === 'a' || value === 'b' || value === 'c') {
        //     setRateCards(productsData[country][value])
        //     localStorage.setItem('period', JSON.stringify(productsData[country][value][1]))
        //     setActiveRate(productsData[country][value][1])
        //     setTest(value)
        //     amplitude.getInstance().logEvent('screen_onboarding_paywall_appeared', { paywall: `${value}_${country}`});
        // }
        // if(discountStatus && discountStatus === 'trial') {
        //     setTextButton('Start my 7 day trial')
        //     setRateCards(productsData[country]['trial'])
        //     localStorage.setItem('period', JSON.stringify(productsData[country]['trial'][1]))
        //     setActiveRate(productsData[country]['trial'][1])
        //     setTest('trial')
        //     amplitude.getInstance().logEvent('screen_onboarding_paywall_appeared', {paywall: `trial_${country}`});
        // }
    // }, [value, discountStatus])
    }, [discountStatus, nutritionButton])

    useEffect(() => {
        localStorage.setItem('period', JSON.stringify(activeRate))
    }, [activeRate])

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const mainContainerClass = `${styles.mainContainer} ${pageInfo.greenTheme ? styles.lightGreenStyle : styles.defaultStyle} ${styles[`${themeData.pageContainer}Container`]} `

    return (
        <div className={mainContainerClass}>
            { pageInfo.greenTheme ? (
                <>
                    { themeData.paywallBackgroundImages && (<PaywallBackground images={themeData.paywallBackgroundImages} />)}
                    <div className={styles.logoContainer}>
                        <img loading="lazy" className={styles.logoImage} src={themeData.stepLogo.src} alt="" />
                    </div>
                    { discountStatus && !data.partner && discountStatus === 'trial' && (
                        <StartTrial style={pageInfo.style} />
                    ) }
                    { pageInfo.xPercentDiscount && (
                        <XPercentDiscount style={pageInfo.style} discount={70}/>
                    ) }
                    { data.campaign !== 'sh032470' && discountStatus && !data.partner && discountStatus === 'discount' && (
                        <StartDiscount style={pageInfo.style} />
                    ) }
                    <div className={styles.beforeTitle}>
                        {strings.paywall.greenTheme.beforeTitle}
                    </div>
                    <h1 className={`${styles.title} ${pageInfo.cfTitle ? styles.cfTitle : ''}`}>
                        {pageInfo.cfTitle ?
                            strings.paywall.greenTheme.cfTitle :
                           ( pageInfo.grab !== null && pageInfo.grab !== undefined) ? strings.paywall.greenTheme[pageInfo.grab.reach] : strings.paywall.greenTheme.title
                        }
                    </h1>
                    {pageInfo.achieveImages && <div className={styles.swiperArea}>
                        <HeaderSwiper
                            data={pageInfo.achieveImages}
                            currentWeight={paywallCurrentWeight}
                            targetWeight={targetWeight}
                            theme={themeData}
                        />
                    </div>}
                    {pageInfo.videos && <div className={styles.swiperArea}>
                                            <HeaderSwiper
                                                data={pageInfo.videos}
                                                currentWeight={paywallCurrentWeight}
                                                targetWeight={targetWeight}
                                                theme={themeData}
                                            />
                    </div>}
                    { pageInfo.specialTitle && (
                        <SpecialTitle
                            data={pageInfo.specialTitle}
                            theme={themeData.pageContainer}
                        />
                    )}
                    <MealPlanWithTimer
                        timerRef={timerRef}
                        activeRate={activeRate}
                        stopTimer={stopTimer}
                        setStopTimer={setStopTimer}
                        themeData={themeData}
                        settings={pageInfo.mealPlan}
                    />
                    <div className={`${styles.planTitle} ${pageInfo.cfTitle ? styles.cfPlanTitle : ''} flex-column`}>
                        {pageInfo.cfTitle ?
                            strings.paywall.greenTheme.cards.cfTitleOne :
                            (( pageInfo.grab !== null && pageInfo.grab !== undefined) ? strings.paywall.greenTheme.cards[pageInfo.grab.titleOne] : strings.paywall.greenTheme.cards.titleOne)
                        }
                        <span>{pageInfo.planType
                               ? strings.paywall.greenTheme.cards['title' + pageInfo.planType ]
                               : (( pageInfo.grab !== null && pageInfo.grab !== undefined) ? strings.paywall.greenTheme.cards[pageInfo.grab.titleTwo] : strings.paywall.greenTheme.cards.titleTwo)}</span>

                        {strings.paywall.greenTheme.cards.titleThree}
                    </div>
                    { data.nutrition !== null && data.nutrition === 'onh' && (<NutritionSwitcher onClickAction={setNutritionButton} />) }
                    <div className={styles.paymentRateCards}>
                        <PaymentRateCards
                            textButton={textButton}
                            nextPage={() => nextPage(2)}
                            rateCards={rateCards}
                            pageInfo={pageInfo}
                            activeRate={activeRate}
                            setActiveRateCard={setActiveRateCard}
                            discount={(discountStatus && discountStatus === 'discount')
                                ? (data.campaign === "sh032470" ? 75 : 60)
                                : (data.campaign === "shret022470" || data.campaign === "sh032470" || data.campaign === "chl_ret70" || data.campaign === "ret70" )
                                    ? 70
                                    : 50}
                            style={pageInfo.style}
                            themeData={themeData}
                        />
                        <p className={styles.notifyText}>{strings.paywall.greenTheme.cards.notifyBox.notifyText}</p>
                    </div>
                    <DownloadAppsBox themeData={themeData} />
                    {pageInfo.feedbacks && (<VideoFeedbacks
                        themeData={themeData}
                        feedbacks={pageInfo.feedbacks}
                        title={true}
                        name={true}
                        view="circle"
                    />)}
                    <StayOnTopBox data={data} themeData={themeData} />
                    <p className={styles.disclaimer}><b>{strings.paywall.disclaimer}</b>{strings.paywall.key}</p>
                    <GetButton
                        text={textButton}
                        nextPage={() => nextPage(3)}
                        style={pageInfo.style}
                        themeData={themeData}
                    />
                </>
                ) : (
                <>
                    <div className={`${styles.logoContainer} flex-between`}>
                        <img loading="lazy" src="/images/logo-black.svg" alt="" />
                        {
                            !stopTimer ? (
                                <ErrorBoundary>
                                    <PaywallTimer
                                        innerRef={timerRef}
                                        activeRate={activeRate}
                                        stopTimer={stopTimer}
                                        setStopTimer={setStopTimer}
                                        onlyTime={false}
                                    />
                                </ErrorBoundary>
                            ) : (
                                <GetButton small={true} text={textButton} nextPage={() => nextPage(1)}/>
                            )
                        }
                    </div>
                    { discountStatus && !data.partner && discountStatus === 'trial' && (<StartTrial />) }
                    { data.campaign !== 'sh032470' && discountStatus && !data.partner && discountStatus === 'discount' && (<StartDiscount />) }
                    { pageInfo.xPercentDiscount && (<XPercentDiscount discount={70}/>) }
                    { discountStatus && !data.partner && discountStatus === 'discount' && data.campaign === 'sh032470' && (<XPercentDiscount previousDiscount = {(discountStatus && !data.partner && discountStatus === 'discount')? 70 : null } discount={(discountStatus && !data.partner && discountStatus === 'discount')? 75 : 70 }/>) }
                    {
                        pageInfo.campaignImageSale ?
                            (<ImageSaleAd campaign={data.campaign} pageInfo={pageInfo} style={pageInfo.style} />) :
                            (<img loading="lazy" className={styles.galleryImage} src="/images/paywall/a/paywall-a.gif" alt="" />)
                    }
                    <h1 className={styles.title1}>{strings.paywall.title.start}<span>{strings.paywall.title.personal}</span></h1>
                    <h1 className={styles.title1}>{strings.paywall.title.end}</h1>
                    <div className={`${styles.selectedDataContainer} flex-between`}>
                        <div className={`${styles.selectedItem} flex-row`}>
                            <div className={styles.circleContainer}>
                                <LazyImage
                                    src="/images/result/goal-result.jpg"
                                    srcWebp="/images/result/goal-result.webp"
                                    alt=""
                                />
                            </div>
                            <div className={`${styles.itemText} flex-column`}>
                                <p className={styles.label}>{strings.paywall.goal}</p>
                                <p className={styles.content}>{goal}</p>
                            </div>
                        </div>
                        <div className={styles.verticalLine}></div>
                        <div className={`${styles.selectedItem} flex-row`}>
                            <div className={styles.circleContainer}>
                                <LazyImage
                                    src="/images/result/scales.jpg"
                                    srcWebp="/images/result/scales.webp"
                                    alt=""
                                />
                            </div>
                            <div className={`${styles.itemText} flex-column`}>
                                <p className={styles.label}>{strings.paywall.targetWeight}</p>
                                <p className={styles.content}>{targetWeight}</p>
                            </div>
                        </div>
                    </div>
                    <MealPlanBonus campaign={data.campaign}
                                   img={(discountStatus && discountStatus === 'discount')
                                       ? strings.imgs[pageInfo.mealBonusImgLocalId]
                                       : (discountStatus && discountStatus === 'trial')
                                           ? strings.imgs[pageInfo.mealBonusImgLocalId]
                                           : strings.imgs[pageInfo.mealBonusImgLocalId]}
                                   style={pageInfo.style}
                                   discount={!!(discountStatus && discountStatus === 'discount') }
                                   withNutrition={(data.nutrition !== null && data.nutrition === 'onh')}
                                   nextText = {textButton}
                    />
                    <h1 className={styles.title1}>{strings.paywall.cardHeader.title[pageInfo.cardsTitleID]}</h1>
                    { data.nutrition !== null && data.nutrition === 'onh' && (<NutritionSwitcher onClickAction={setNutritionButton} />) }
                    <PaymentRateCards
                        textButton={textButton}
                        nextPage={() => nextPage(2)}
                        rateCards={rateCards}
                        pageInfo={pageInfo}
                        activeRate={activeRate}
                        setActiveRateCard={setActiveRateCard}
                        discount={(discountStatus && discountStatus === 'discount')
                            ? (data.campaign === "sh032470" ? 75 : 60)
                            : (data.campaign === "shret022470" || data.campaign === "sh032470" || data.campaign === "chl_ret70" || data.campaign === "ret70" )
                                ? 70
                                : 50}
                        style={pageInfo.style}
                        themeData={themeData}
                    />
                    <p className={styles.rateNote}>{strings.paywall.cancelAnytime}</p>
                    <h2 className={`${styles.titleProgramDescription} ${styles.marginTitle}`}>{strings.paywall.featured}</h2>
                    <LazyImage
                        className={styles.featuresImage}
                        src="/images/paywall/features-image.png"
                        srcWebp="/images/paywall/features-image.webp"
                        alt=""
                    />
                    <div className={`${styles.resultsContainer} resultsContainer flex-column`}>
                        <h2>{strings.paywall.peopleLikeYou}<span>{strings.paywall.plan}</span></h2>
                        <Swiper
                            className="mySwiper"
                            spaceBetween={30}
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                        >
                            {
                                feedbacks.map(result => (
                                    <SwiperSlide key={result.id}>
                                        <ResultsCard card={result}/>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                    <p className={styles.disclaimer}><b>{strings.paywall.disclaimer}</b>{strings.paywall.key}</p>
                    <PaymentRateCards
                        textButton={textButton}
                        nextPage={() => nextPage(3)}
                        rateCards={rateCards}
                        pageInfo={pageInfo}
                        activeRate={activeRate}
                        setActiveRateCard={setActiveRateCard}
                        discount={(discountStatus && discountStatus === 'discount')
                            ? (data.campaign === "sh032470" ? 75 : 60)
                            : (data.campaign === "shret022470" || data.campaign === "sh032470" || data.campaign === "chl_ret70" || data.campaign === "ret70")
                                ? 70
                                : 50}
                        style={pageInfo.style}
                        themeData={themeData}
                    />
                </>
                )
            }
            <p className={styles.rateNote}>{strings.paywall.cancelAnytime}</p>
            <h2 className={styles.questionsTitle}>{strings.paywall.peopleAsk}</h2>
            {
                content.questions.map(question => (
                    <div className={styles.questionContainer} key={question.id}>
                        <div className={`${styles.titleContainer} flex-row`}>
                            <LazyImage
                                src="/images/paywall/question-icon.png"
                                srcWebp="/images/paywall/question-icon.webp"
                                alt=""
                            />
                            <p>{strings.paywall.questions[question.id].title}</p>
                        </div>
                        <p className={styles.subtitle}>{strings.paywall.questions[question.id].subtitle}</p>
                    </div>
                ))
            }
            <h2 className={`${styles.questionsTitle} ${styles.marginFeedbackTitle}`}>{strings.paywall.love}</h2>
            {
                content.comments.map(comment => (
                    <div key={comment.id} className={styles.commentContainer}>
                        <div className={styles.titleContainer}>
                            <h2>{strings.paywall.comments[comment.id].title}</h2>
                            <LazyImage
                                src="/images/paywall/stars.png"
                                srcWebp="/images/paywall/stars.webp"
                                alt=""
                            />
                        </div>
                        <p>{strings.paywall.comments[comment.id].subtitle}</p>
                        <div className={`${styles.authorContainer} flex-between`}>
                            <span>{strings.paywall.comments[comment.id].author}</span>
                            <span>{strings.paywall.comments[comment.id].date}</span>
                        </div>
                    </div>
                ))
            }
            <GuaranteeBox style={pageInfo.style} />
            <p className={styles.guaranteeNote}><b>{strings.paywall.note}</b>{strings.paywall.subscrRenew}</p>
            <GetButton
                style={pageInfo.style}
                text={textButton}
                nextPage={() => nextPage(4)}
                themeData={themeData}
            />
            <div className={styles.bottomSpace}></div>
        </div>
    )
}

export default Paywall;